@import "variables";
@import "mixins/breakpoints";
@import "mixins/button";
@import "mixins/grid";

// Variables

$grid-gutter: 15px;

// Implementation

// @import "grid_two";

.group__classes {
  position: absolute;
  bottom: 1px;
  right: 1px;
  background: $gray-200;
  color: $gray-600;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px dashed $gray-200;
  border-left: 0;
  border-top: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
  z-index: 10;

  svg {
    width: 14px;
    vertical-align: middle;
  }

  .table & {
    display: none;
    position: absolute;
    top: -1px;
  }
}

// Content
.content-group {
  --padding: 24px;
  --gap: 10px;
  position: relative;
  display: flex;
  flex: 1 1 0;
  max-width: 100%;
  min-height: 100%;

  ul {
    padding-left: 20px;
  }
}

.content-group__content--padding {
  padding-top: var(--padding);
  padding-bottom: var(--padding);
}

.content-group__content {
  position: relative;

  &.content-group--row {
    display: flex;
    gap: var(--gap);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &>* {
      margin: 0;
    }
  }
}

// TODO: do we need this? (maybe for image plugin to fill entire space)
.content-group__inner {
  width: 100%;
}

// Themes

.content-group__theme-dark {
  color: $white;

  .button {
    @include button-white;

    // link color overrides
    &.button--black {
      @include button-black;
    }

    &.button--orange {
      @include button-orange;
    }
  }
}

.content-group__theme-light {
  color: $gray-900;

  .button {
    @include button-black;

    // link color overrides
    &.button--white {
      @include button-white;
    }

    &.button--orange {
      @include button-orange;
    }
  }
}

// Background image
.content-group__background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &[data-align^="top-"] {
    background-position-y: top;
  }

  &[data-align^="middle-"] {
    background-position-y: center;
  }

  &[data-align^="bottom-"] {
    background-position-y: bottom;
  }

  &[data-align$="-left"] {
    background-position-x: left;
  }

  &[data-align$="-center"] {
    background-position-x: center;
  }

  &[data-align$="-right"] {
    background-position-x: right;
  }
}

// Alignment
.content-group {
  &[data-align^="top-"] {
    align-items: flex-start;
  }

  &[data-align^="middle-"] {
    align-items: center;
  }

  &[data-align^="bottom-"] {
    align-items: flex-end;

    .content-group__content {
      &:not(.content-group--row) {
        padding-bottom: 20px;
      }
    }
  }

  &[data-align$="-left"] {
    justify-content: flex-start;

    .content-group__content {
      &:not(.content-group--row) {

        .text,
        .countdown,
        .icon-link .icon-link__link {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }

  &[data-align$="-center"] {
    justify-content: center;

    .content-group__content {
      &:not(.content-group--row) {
        text-align: center;

        ul {
          display: inline-block;
          text-align: left;
        }

        .countdown,
        .icon-link .icon-link__link {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  &[data-align$="-right"] {
    justify-content: flex-end;

    .content-group__content {
      &:not(.content-group--row) {
        text-align: right;

        ul {
          display: inline-block;
          text-align: left;
        }

        .text,
        .countdown,
        .icon-link .icon-link__link {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }
  }
}
